import React, { useState } from 'react';
import Login from '../../screens/login'
import HomeScreen from '../../screens/home';
import MainRoutes from '../../routes/MainRoutes';
import { Layout, Menu, Breadcrumb } from 'antd';
import { PageHeader } from 'antd';
import Ziiki from '../../assets/ziiki.png';
import {
    DesktopOutlined,
    PieChartOutlined,
    FileOutlined,
    FileAddOutlined,
    UserAddOutlined ,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const MainLayout = () => {
    const [collapsed, setCollapsed] = useState(false);
    const onCollapse = () => setCollapsed(value => !value);
  
    return (
       
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                    <div className="logo"><img class="headLogo" src={Ziiki}/></div>

                    <Menu theme="dark" defaultSelectedKeys={['1']}  mode="inline">
                        <Menu.Item key="1" icon={<FileAddOutlined />}>
                        <Link to="/Home">Create Landing Page</Link>
                        </Menu.Item>
                        <Menu.Item key="1" icon={<DesktopOutlined />}>
                        <Link to="/list">Published Landing Pages</Link>
                        </Menu.Item>
                        <Menu.Item key="1" icon={<UserOutlined />}>
                        <Link to="/login">Logout</Link>
                        </Menu.Item>
                       
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0,backgroundColor:'#002140' }} >
                    
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                    <MainRoutes></MainRoutes>
                    </Content>
                    {/*<Footer  style={{textAlign: 'center', backgroundColor:'#ffffff' }}></Footer>*/}
                </Layout>
            </Layout>
        
    );
};

/*const fakeAuth = {
    isAuthenticated: false,
    authenticate(cb) {
      fakeAuth.isAuthenticated = true;
      setTimeout(cb, 100); // fake async
    },
    signout(cb) {
      fakeAuth.isAuthenticated = false;
      setTimeout(cb, 100);
    }
  };
  
  function AuthButton() {
    let history = useHistory();
  
    return fakeAuth.isAuthenticated ? (
      <p>
        Welcome!{" "}
        <button
          onClick={() => {
            fakeAuth.signout(() => history.push("/"));
          }}
        >
          Sign out
        </button>
      </p>
    ) : (
      <p>You are not logged in.</p>
    );
  }

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          fakeAuth.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }*/

export default MainLayout;