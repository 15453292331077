import React from 'react';
import './App.css';
import MainLayout from './components/menu/menu';
import 'antd/dist/antd.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import Login from './screens/login/index';
import { Helmet } from 'react-helmet'

const TITLE = 'Ziki Music'
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/' component={Login} />
        <MainLayout></MainLayout>
       
      </Switch>

    </Router>
  );
}

export default App;
