import React, { useState, useEffect } from 'react';
import { Table, Breadcrumb, Popconfirm, Divider } from 'antd';
import { catalog, catalogDelete } from '../../services/API';
import Moment from 'moment';
import { BASE_URL } from '../../services/constants';



const List = ({ prop }) => {
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        fetchUser();
    }, []);


    const fetchUser = async () => {

        catalog().then(data => {
            console.log(data);
            setFinalData(data.data);
        },
            error => {
            }
        );
    }

    const columns = [
        {
            title: 'DATE',
            dataIndex: 'dates',
            key: 'dates',  //Moment("1994-07-01").format('DD-MM-YYYY'),
            render: (text, record) =>
                Moment(text).format('DD-MM-YYYY'),
        },
        {
            title: 'Song Name',
            dataIndex: 'songName',
            key: 'songName',
            //children:'subcategory'
        },
        {
            title: 'Artist',
            dataIndex: 'artist',
            key: 'status',
        },
        {
            title: 'Airtel Code',
            dataIndex: 'airtelCode',
            key: 'airtelCode',
            //children:'subcategory'
        },
        {
            title: 'MTN Code',
            dataIndex: 'mtnCode',
            key: 'mtnCode',
            //children:'subcategory'
        },
        {
            title: 'Vodafone Code',
            dataIndex: 'vodaCode',
            key: 'vodaCode',
            //children:'subcategory'
        },
        {
            title: 'NineMobile Code',
            dataIndex: 'NineMobileCode',
            key: 'NineMobileCode',
            //children:'subcategory'
        },
        {
            title: 'Econet Code',
            dataIndex: 'EconetCode',
            key: 'EconetCode',
            //children:'subcategory'
        },
        {
            title: 'Globacom Code',
            dataIndex: 'GlobacomCode',
            key: 'GlobacomCode',
            //children:'subcategory'
        },
        {
            title: 'Halotel Code',
            dataIndex: 'HalotelCode',
            key: 'HalotelCode',
            //children:'subcategory'
        },
        {
            title: 'MPTMyanmar Code',
            dataIndex: 'MPTMyanmarCode',
            key: 'MPTMyanmarCode',
            //children:'subcategory'
        },
        {
            title: 'MyTel Code',
            dataIndex: 'MyTelCode',
            key: 'MyTelCode',
            //children:'subcategory'
        },
          {
            title: 'Ooredo Code',
            dataIndex: 'OoredoCode',
            key: 'OoredoCode',
            //children:'subcategory'
        },
            {
            title: 'Safaricom Code',
            dataIndex: 'SafaricomCode',
            key: 'SafaricomCode',
            //children:'subcategory'
        },
          {
            title: 'Telenor Code',
            dataIndex: 'TelenorCode',
            key: 'TelenorCode',
            //children:'subcategory'
        },
        {
            title: 'Tigo Code',
            dataIndex: 'TigoCode',
            key: 'TigoCode',
            //children:'subcategory'
        },
        {
            title: 'Vodacom Code',
            dataIndex: 'VodacomCode',
            key: 'VodacomCode',
            //children:'subcategory'
        },
        {
            title: 'Zamtel Code',
            dataIndex: 'ZamtelCode',
            key: 'ZamtelCode',
            //children:'subcategory'
        },
        /* {
             title: 'Banner',
             dataIndex: 'banner',
             key: 'banner',
             width: '12%',
             render:  (text, record, i) =>
             <img style={{height : '40px', widows : '40px'}} src="http://localhost:3002/index?id=/{text}" />
         },*/
        {
            title: 'Published Page',
            dataIndex: '_id',
            key: '_id',
            render: (text, record) =>
                <a target="_blank" onClick={() => handleOpen({ text })}>VIEW</a>


        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (text, record) =>
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record._id)}>
                    <a>Delete</a>
                </Popconfirm>

        }


    ];

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    };

    const handleDelete = key => {
        console.log(key)
        const dataSource = [...finalData];

        catalogDelete(key).then(data => {
            setFinalData(data.data);
        },
            error => {
            }
        );

    };

    const handleOpen = key => {
        console.log(key.text);
        window.open(BASE_URL + '/song?id=' + key.text);

    };



    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item> Published Page</Breadcrumb.Item>


            </Breadcrumb>
            <Divider></Divider>
            <Table columns={columns} childrenColumnName="subcategory" scroll={{ x: 400 }}
                rowkey="_id" dataSource={finalData} />
        </>
    );
};

export default List