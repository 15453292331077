import Axios from 'axios';
import { BASE_URL } from './constants';


const qs = require('qs')

const axios = Axios.create({
    baseURL: BASE_URL,
    timeout: 1000000,
    headers: {
        'Content-Type': 'application/json',
    },
    validateStatus: function (status) {
        return status == 200;
    }
});

axios.interceptors.request.use(
    config => {

        // Do something before request is sent
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');;
        }
        return config;
    },
    error => {
        console.log('API ERR:', error.message);
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    response => response,
    error => {
        console.log('API ERR:', error);
        return Promise.reject(error);
    },
);




export const catalog = async () => {
    try {
        return await axios.get('/catalog');
    } catch (err) {
        return err;
    }
};

export const catalogSave = async (data) => {

    return await axios.post('/catalog', data);

};

export const catalogDelete = async (data) => {
    try {
        return await axios.delete('/catalog/' + data);
    } catch (err) {
        return err;
    }
};



export const customRequest = async (options, type) => {
    const data = new FormData()
    data.append('mypic', options.file);
    data.set('userName', 'Fred');
    const config = {
        "headers": {
            "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
        }
    }
    return await axios.post(options.action, data, config);

}

export const login = async (data) => {

    return await axios.post('/catalog/login', data);

};



