import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../screens/home/index';
import List from '../screens/list/index';
import Login from '../screens/login/index';

export const MainRoutes = () => (
    <Switch>
        <Route exact path='/home' component={Home} />   
        <Route exact path='/list' component={List} />   
       
    </Switch>
)

export default MainRoutes;