import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Row, Col, Divider, Checkbox,Breadcrumb, Alert } from 'antd';
import { Form, Upload,Text, Input, Select, InputNumber, Button, notification } from 'antd';
import { customRequest, catalogSave } from '../../services/API';
import { FILE_URL } from '../../services/constants';
import { useHistory } from 'react-router-dom';
import { BASE_URL } from '../../services/constants';

import {
  SmileOutlined,
  UploadOutlined
} from '@ant-design/icons';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Airtel', 'MTN', 'Vodafone', '9mobile', 'Econet','Globacom','Halotel','MPTMyanmar','MyTel','Ooredo','Safaricom','Telenor', 'Tigo', 'Vodacom','Zamtel' ];
const defaultCheckedList = [];

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not validate email!',
    number: '${label} is not a validate number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

const normFile = e => {
  if (Array.isArray(e)) {
    return e;
  }
  return e.fileList.slice(-1);
  //return e && e.fileList;

};
  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      console.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      console.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }
const openNotification = () => {
  notification.open({
    message: 'Product added successfully',
    //description:
    //  'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    icon: <SmileOutlined style={{ color: '#108ee9' }} />,
  });
};



const Home = () => {
  //['Airtel', 'MTN', 'Vodafone', 'NineMobile', 'Econet','Globacom','Halotel','MPTMyanmar','MyTel','Ooredo','Safaricom','Telenor', 'Tigo', 'Vodacom','Zamtel' ];
  const [finalData, setFinalData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [showAirtel, setShowAirtel] = useState(false);
  const [showMtn, setShowaMtn] = useState(false);
  const [showVoda, setShowVoda] = useState(false);
  const [showNineMobile, setShowNineMobile] = useState(false);
  const [showEconet, setShowEconet] = useState(false);
  const [showGlobacom, setShowGlobacom] = useState(false);
  const [showHalotel, setShowHalotel] = useState(false);
  const [showMPTMyanmar, setShowMPTMyanmar] = useState(false);
  const [showMyTel, setShowMyTel] = useState(false);
  const [showOoredo, setShowOoredo] = useState(false);
  const [showTelenor, setShowTelenor] = useState(false);
  const [showTigo, setShowTigo] = useState(false);
  const [showVodacom, setShowVodacom] = useState(false);
  const [showZamtel, setShowZamtel] = useState(false);
  const [showSafaricom, setShowSafaricom] = useState(false);

  const [retId, setRetId] = useState('');
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [form] = Form.useForm();
  useEffect(() => {
   
  }, []);
  const history = useHistory();

  const onFinish = values => {
   // alert(checkedList)
    //if(checkedList.length>0){
    values.product.banner = values.product.banner[0].response.data;
    
    
    values.product.dates = new Date;
    console.log(values.product);
    catalogSave(values.product).then(data => {

      setSuccess(true);
      console.log(data.data);
      setRetId(data.data._id);
      form.resetFields();
      openNotification();
      history.push('/list');
    },
      error => {
      }
    );
     

  };

 const  onChange = checkedList => {
      console.log(checkedList);
      //'9mobile', 'Econet','Globacom','Halotel','MPTMyanmar','MyTel','Ooredo','Safaricom','Telenor', 'Tigo', 'Vodacom','Zamtel' ];
      setShowAirtel(checkedList.includes('Airtel'));  
      setShowaMtn(checkedList.includes('MTN'));  
      setShowVoda(checkedList.includes('Vodafone'));
      setShowNineMobile(checkedList.includes('9mobile'));  
      setShowGlobacom(checkedList.includes('Globacom'));
      setShowHalotel(checkedList.includes('Halotel'));
      setShowMPTMyanmar(checkedList.includes('MPTMyanmar'));
      setShowMyTel(checkedList.includes('MyTel'));  
      setShowOoredo(checkedList.includes('Ooredo'));
      setShowSafaricom(checkedList.includes('Safaricom'));
      setShowTelenor(checkedList.includes('Telenor'));
      setShowVodacom(checkedList.includes('Vodacom'));
      setShowZamtel(checkedList.includes('Zamtel'));
      setShowEconet(checkedList.includes('Econet'));
      setShowTigo(checkedList.includes('Tigo'));
      
   
  };
  

  const customRequests = (options) => {
    const { onSuccess, onError, file, action, onProgress } = options;
    customRequest(options).then(res => {
      /* form.setFieldsValue({
           user:
           {
               categoryThumbnail: res.data,
           }
       });*/
      onSuccess(res);
    },
      error => {
        onError(error);
      }
    );

  }

  const handleOpen = key => {
    console.log(retId);
    window.open(BASE_URL+'/index?id='+retId);

};

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Create Page</Breadcrumb.Item>
        

      </Breadcrumb>

      <Divider></Divider>
      <Row justify="start">

        <Col span={24}>
          <Form form={form} {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
            <Row>
              <Col
                span={10}
                style={{
                  textAlign: 'right',
                }}
              >
                <Form.Item
                  name={['product', 'songName']}
                  label="Song Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                
                <Form.Item
                  name={['product', 'album']}
                  label="Album"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['product', 'artist']}
                  label="Artist"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>


              <Form.Item name="checkbox-group" label="Operators">
              <CheckboxGroup
                  options={plainOptions}
                  value={checkedList}
                  onChange={onChange}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />
              </Form.Item>
              
                {showAirtel?
                <>
                <Form.Item
                  name={['product', 'airtelCode']}
                  label="Airtel Song Code"
                  
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                 <Form.Item
                 name={['product', 'airtelDesc']}
                 label="Airtel Description"
                 
                 rules={[
                   {
                     required: true,
                   },
                 ]}
               >
                 <Input />
               </Form.Item>
               </>
               :null}  

            {showMtn?
            <>
             < Form.Item
                  name={['product', 'mtnCode']}
                  label="MTN Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['product', 'mtnDesc']}
                  label="MTN Description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                  </>

                  :null}        

                {showVoda?
                <>
                <Form.Item
                  name={['product', 'vodaCode']}
                  label="Vodafone Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'vodaDesc']}
                  label="Vodafone  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}

          {showNineMobile?
                <>
                <Form.Item
                  name={['product', 'NineMobileCode']}
                  label="9mobile Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'NineMobileDesc']}
                  label="9mobile  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}

        {showEconet?
                <>
                <Form.Item
                  name={['product', 'EconetCode']}
                  label="Econet Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'EconetDesc']}
                  label="Econet  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}

        {showGlobacom?
                <>
                <Form.Item
                  name={['product', 'GlobacomCode']}
                  label="Globacom Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'GlobacomDesc']}
                  label="Globacom  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}

          {showHalotel?
                <>
                <Form.Item
                  name={['product', 'HalotelCode']}
                  label="Halotel Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'HalotelDesc']}
                  label="Halotel  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}

{showMPTMyanmar?
                <>
                <Form.Item
                  name={['product', 'MPTMyanmarCode']}
                  label="MPTMyanmar Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'MPTMyanmarDesc']}
                  label="MPTMyanmar  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}


{showMyTel?
                <>
                <Form.Item
                  name={['product', 'MyTelCode']}
                  label="MyTel Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'MyTelDesc']}
                  label="MyTel  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}

{showOoredo?
                <>
                <Form.Item
                  name={['product', 'OoredoCode']}
                  label="Ooredo Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'OoredoDesc']}
                  label="Ooredo  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}


  {showSafaricom?
                <>
                <Form.Item
                  name={['product', 'SafaricomCode']}
                  label="Safaricom Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'SafaricomDesc']}
                  label="Safaricom  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}  

   {showTelenor?
                <>
                <Form.Item
                  name={['product', 'TelenorCode']}
                  label="Telenor Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'TelenorDesc']}
                  label="Telenor  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}  

    {showTigo?
                <>
                <Form.Item
                  name={['product', 'TigoCode']}
                  label="Tigo Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'TigoDesc']}
                  label="Tigo  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null} 

{showVodacom?
                <>
                <Form.Item
                  name={['product', 'VodacomCode']}
                  label="Vodacom Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'VodacomDesc']}
                  label="Vodacom  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}  

   {showZamtel?
                <>
                <Form.Item
                  name={['product', 'ZamtelCode']}
                  label="Zamtel Song Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                <Form.Item
                  name={['product', 'ZamtelDesc']}
                  label="Zamtel  description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />

                </Form.Item>
                </>
                :null}                                         
               
               
                <Form.Item
                  name={['product', 'banner']}
                  label="Banner"
                  valuePropName="fileList"
                  //data={'Category'}
                  getValueFromEvent={normFile}
                  extra=""
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Upload action={FILE_URL+'/upload'} customRequest={customRequests} beforeUpload={beforeUpload}>
                    <Button>
                      <UploadOutlined /> Upload Banner
                                            </Button>
                                            *maximum up to 2 MB.
                  </Upload>
                </Form.Item>
               
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                  <Button type="primary" htmlType="submit">
                    Submit
               </Button>
                </Form.Item>

               
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};


export default Home








