import { Form, Input, Button, Checkbox, Divider } from 'antd';
import React from 'react';
import { Layout } from 'antd';
import { Row, Col, Spin } from 'antd';
import { Switch, Link } from 'react-router-dom';
import Ziiki from '../../assets/ziiki.png';
import { login } from '../../services/API';
import { useHistory } from 'react-router-dom';

import {
    LockOutlined,
    UserOutlined
} from '@ant-design/icons';

const { Header, Footer, Sider, Content } = Layout;

const Login = (props) => {
    const history = useHistory();
    const onFinish = values => {
        console.log(values);
        login(values).then(data => {
            console.log(data);
            if(data.data.code=='success'){
                
                history.push('/home');
            }else{
                alert("Please enter corecct username and password");
            }
        },
            error => {
            }
        );
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
   <div>


            <Layout className="wrapperbg_front" style={{ height: "100vh", align: "middle" }} >
                <Header style={{ backgroundColor: '#b2b2b2' }}>
                    <Row>
                        <Col className=""><img className="logomain" src={Ziiki} /></Col>
                    </Row>
                </Header>


                <Content style={{ height: "100vh", align: "middle" }}>

                    <Row style={{ height: "10vh", align: "middle" }}>
                        <Col className=""></Col>
                    </Row>
                    <Row >
                        <Col span={8}></Col>
                        <Col span={8}>
                            <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                            >

                                <Form.Item
                                    name="user"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Username!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                                </Form.Item>
                                <Form.Item name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Password!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Item>


                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                     

                                        Submit
                                    </Button>

                                </Form.Item>
                            </Form>
                        </Col>

                    </Row>


                </Content>

            </Layout>
        </div>
    );
};


export default Login;


